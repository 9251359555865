type Config = {
  baseApiUrl: string;
  endPoints: {
    registerUser: string;
    resetPassword: string;
    changePassword: string;
    registerMentor: string;
    registerAdmin: string;
    getUserInfoUrl: string;
    getAllVideosInfoUrl: string;
    getAllAdvertisementsUrl: string;
    getLastAdvertisementsUrl: string;
    getLearnSectionCardsUrl: string;
    getLearnSubsectionCardsUrl: string;
    getLearnSubsectionContentUrl: string;
    addMessage: string;
    getAllMessages: string;
    getAllStudents: string;
    getAllMentors: string;
    getAllAdmins: string;
    editUser: string;
    getStudent: string;
    activateUser: string;
    deactivateUser: string;
    deleteUser: string;
    changeMessageStatus: string;
    createMessageResponse: string;
    createAdvertisement: string;
    editAdvertisement: string;
    deleteAdvertisement: string;
    createVideo: string;
    editVideo: string;
    deleteVideo: string;
    getAllSections: string;
    getAllSubsections: string;
    getAllSectionsTree: string;
    createSection: string;
    createSubsection: string;
    addSubsectionContent: string;
    editSection: string;
    editSubsection: string;
    editSubsectionContent: string;
    deleteSection: string;
    deleteSubsection: string;
    deleteSubsectionContent: string;
    getAllTasks: string;
    addTask: string;
    editTask: string;
    deleteTask: string;
    addResponseTask: string;
    setTaskStatusRework: string;
    setTaskStatusComplete: string;
    logout: string;
    getFile: string;
  };
};

export const config: Config = {
  baseApiUrl: "/api/ui",
  endPoints: {
    registerUser: '/users/register',
    resetPassword: '/users/resetPass',
    changePassword: '/users/changePass',
    registerMentor: '/users/register/mentor',
    registerAdmin: '/users/register/admin',
    getUserInfoUrl: "/userInfo",
    getAllVideosInfoUrl: "/videos/",
    getAllAdvertisementsUrl: "/advertisements/",
    getLastAdvertisementsUrl: "/advertisements/last/",
    getLearnSectionCardsUrl: "/learn/",
    getLearnSubsectionCardsUrl: "/learn/{sectionId}/",
    getLearnSubsectionContentUrl: "/learn/{sectionId}/{subsectionId}/",
    addMessage: "/messages",
    getAllMessages: "/messages/",
    getAllStudents: "/users/students",
    getAllMentors: "/users/mentors",
    getAllAdmins: "/users/admins",
    editUser: '/users/{userId}',
    getStudent: '/users/{userId}',
    activateUser: '/users/{userId}/activate',
    deactivateUser: '/users/{userId}/deactivate',
    deleteUser: '/users/{userId}',
    changeMessageStatus: '/messages/{messageId}',
    createMessageResponse: '/messages/response',
    createAdvertisement: '/advertisements',
    editAdvertisement: '/advertisements/{advertisementId}',
    deleteAdvertisement: '/advertisements/{advertisementId}',
    createVideo: '/videos',
    editVideo: '/videos/{videoId}',
    deleteVideo: '/videos/{videoId}',
    getAllSections: '/learn/',
    getAllSubsections: '/learn/{sectionId}/',
    getAllSectionsTree: '/learn//tree/',
    createSection: '/learn',
    createSubsection: '/learn/{sectionId}',
    addSubsectionContent: '/learn/{sectionId}/{subsectionId}',
    editSection: '/learn/{sectionId}',
    editSubsection: '/learn/{sectionId}/{subsectionId}',
    editSubsectionContent: '/learn//{sectionId}/{subsectionId}/{subsectionBlockId}',
    deleteSection: '/learn/{sectionId}',
    deleteSubsection: '/learn/{sectionId}/{subsectionId}',
    deleteSubsectionContent: '/learn//{sectionId}/{subsectionId}/{subsectionBlockId}',
    getAllTasks: '/tasks/',
    addTask: '/tasks',
    editTask: '/tasks/{taskId}',
    deleteTask: '/tasks/{taskId}',
    addResponseTask: '/tasks/{taskId}/response',
    setTaskStatusRework: '/tasks/{taskId}/rework',
    setTaskStatusComplete: '/tasks/{taskId}/complete',
    logout: '/logout',
    getFile: '/files/{fullFileName}'
  },
};
