import React, {useEffect, useRef, useState} from "react";

import "./admin-mentors-page.scss";
import {
    App,
    Button,
    Flex, Form,
    Input,
    InputRef, Modal,
    Space,
    Table,
    TableColumnsType, TableColumnType,
    TableProps,
    Tag,
    Typography
} from "antd";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";
import {FilterDropdownProps} from "antd/es/table/interface";
import {SearchOutlined} from "@ant-design/icons";
// @ts-ignore
import Highlighter from "react-highlight-words";
import {IconPlus} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {UserInfo} from "../../shared/model/user-info";
import {Message} from "../messages-page/messages-page";

const { Paragraph, Text } = Typography;

export const AdminMentorsPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');

    const [mentorsData, setMentorsData] = useState<UserInfo[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isRegisterMentorModal, setIsRegisterMentorModal] = useState<boolean>(false);
    const [isEditMentorModal, setIsEditMentorModal] = useState<boolean>(false);
    const [isDeleteMentorModal, setIsDeleteMentorModal] = useState<boolean>(false);
    const [selectedMentor, setSelectedMentor] = useState<UserInfo | null>(null);

    const searchInput = useRef<InputRef>(null);

    const [registerMentorForm] = Form.useForm<{ firstName: string; lastName: string;
        patronymic: string; email: string }>();
    const [editMentorForm] = Form.useForm<{ firstName: string; lastName: string;
        patronymic: string; groupName: string; mentorId: number }>();


    useEffect(() => {
        updateMentorsData();
    }, []);

    const updateMentorsData = () => {
        httpClient.axios().get<UserInfo[]>(config.endPoints.getAllMentors).then((response) => {
            setMentorsData(response.data);
        });
    }

    const tableProps: TableProps<UserInfo> = {
        bordered: true,
        size: "middle",
        title: () => {return(<Button onClick={() => {setIsRegisterMentorModal(true)}}
                                     icon={<IconPlus />}>Добавить ментора</Button>)},
        showHeader: true
    };

    type DataIndex = keyof UserInfo;

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<UserInfo> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Введите..`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Найти
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Сбросить
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Применить
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            // @ts-ignore
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const tableColumns: TableColumnsType<UserInfo> = [
        {
            title: 'Идентификатор',
            dataIndex: 'id',
            width: '10%',
            align: 'center',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'ФИО',
            dataIndex: 'fullName',
            width: '30%',
            align: 'center',
            ...getColumnSearchProps('fullName'),
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            width: '15%',
            align: 'center',
        },
        {
            title: 'Статус',
            dataIndex: 'isActive',
            width: '10%',
            render: (_, record) => (
                <Tag color={record.isActive ? "green" : "red"}>{record.isActive ? "Активен" : "Деактивирован / не активирован"}</Tag>
            ),
            align: 'center',
        },
        {
            title: 'Доступные действия',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Flex justify="center" align="center" gap={10} vertical>
                    <Button type="link" onClick={() => {
                        setSelectedMentor(record);
                        setIsEditMentorModal(true);
                    }}>
                        Редактировать
                    </Button>
                    <Button type="link" danger onClick={() => {
                        setSelectedMentor(record);
                        setIsDeleteMentorModal(true);
                    }}>
                        Деактивировать / Удалить
                    </Button>
                    {!record.isActive ? <Button type="link" onClick={() => {
                        if (record) {
                            httpClient.axios().post(config.endPoints.activateUser
                                .replace('{userId}', record.id.toString())).then(() => {

                                setIsDeleteMentorModal(false);
                                notification.success({
                                    message: 'Ментор успешно активирован!'
                                });
                                updateMentorsData();
                            });
                        }
                    }}>
                        Активировать
                    </Button> : null}
                </Flex>
            ),
        }
    ];

    // rowSelection object indicates the need for row selection
    const rowSelection: TableProps<UserInfo>['rowSelection'] = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: UserInfo[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: UserInfo) => ({
            //
        }),
    };

    return (
        <div className="mentor-students-page">
            <div style={{textAlign: "center"}}>
                <Paragraph style={{fontSize: "18pt"}}>Менторы</Paragraph>
            </div>
            <Table<UserInfo>
                {...tableProps}
                pagination={{ position: ["bottomCenter"], pageSize: 6 }}
                columns={tableColumns}
                dataSource={mentorsData}
                rowSelection={{ type: selectionType, ...rowSelection }}
            />
            <Modal
                open={isRegisterMentorModal}
                centered
                onCancel={() => {
                    setIsRegisterMentorModal(false);
                    registerMentorForm.resetFields(["firstName", "lastName", "patronymic", "email"]);
                }}
                onOk={() => {
                    registerMentorForm.validateFields().then((fields) => {
                        httpClient.axios().post(config.endPoints.registerMentor, {
                            firstName: fields.firstName, lastName: fields.lastName,
                            patronymic: fields.patronymic, email: fields.email,
                            password: 'testMentorPass'
                        }).then((response) => {
                            notification.success({
                                message: 'Ментор успешно создан!'
                            })
                            setIsRegisterMentorModal(false);
                            registerMentorForm.resetFields(["firstName", "lastName", "patronymic", "email"]);
                            updateMentorsData();
                        }).catch(() => {
                            navigate('/error')
                        });
                    }).catch(() => {});
                }}
                title="Создать ментора"
                width={600}
                okText="Создать"
                okButtonProps={{shape: "round"}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                <Form
                    form={registerMentorForm}
                    layout="vertical"
                >
                    <Form.Item
                        label="Фамилия:"
                        labelAlign="left"
                        name="lastName"
                        rules={[
                            { required: true, message: 'Фамилия: обязательное поле!' }
                        ]}
                    >
                        <Input placeholder="Введите фамилию.." style={{borderRadius: "32px"}} maxLength={40} />
                    </Form.Item>
                    <Form.Item
                        label="Имя:"
                        labelAlign="left"
                        name="firstName"
                        rules={[{ required: true, message: 'Имя: обязательное поле!' }]}
                    >
                        <Input placeholder="Введите имя.." style={{borderRadius: "32px"}} maxLength={40} />
                    </Form.Item>
                    <Form.Item
                        label="Отчество:"
                        labelAlign="left"
                        name="patronymic"
                    >
                        <Input placeholder="Введите отчество (если оно есть).." style={{borderRadius: "32px"}} maxLength={40} />
                    </Form.Item>

                    <Form.Item
                        label="E-mail:"
                        labelAlign="left"
                        tooltip="Название учетной записи, которая привязана к личному кабинету на сайте УрФУ"
                        name="email"
                        rules={[
                            {required: true, message: 'Введите почту!'},
                            { pattern: /^\w+([\.-]?\w+)*$/, message: "E-mail: неверный формат почты!" }
                        ]}
                    >
                        <Input addonAfter="@urfu.ru" placeholder="Введите почту.." maxLength={30} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={isEditMentorModal}
                centered
                onCancel={() => {
                    setIsEditMentorModal(false);
                    editMentorForm.resetFields(["firstName", "lastName", "patronymic"]);
                }}
                onClose={() => {
                    setIsEditMentorModal(false);
                    editMentorForm.resetFields(["firstName", "lastName", "patronymic"]);
                }}
                onOk={() => {
                    editMentorForm.validateFields().then((editUser) => {
                        const userId = selectedMentor ? selectedMentor.id.toString() : '-';
                        httpClient.axios().put(config.endPoints.editUser.replace('{userId}', userId), {
                            firstName: editUser.firstName ? editUser.firstName : selectedMentor?.firstName,
                            lastName: editUser.lastName ? editUser.lastName : selectedMentor?.lastName,
                            patronymic: editUser.patronymic ? editUser.patronymic : selectedMentor?.patronymic,
                            groupName: null,
                            mentorId: null
                    }).then(() => {
                            notification.success({
                                message: 'Ментор успешно изменен!'
                            })
                            setIsEditMentorModal(false);
                            editMentorForm.resetFields(["firstName", "lastName", "patronymic"]);
                            updateMentorsData();
                        }).catch(() => {
                            navigate('/error')
                        });
                    })
                        .catch(() => {});
                }}
                title="Редактирование ментора"
                width={600}
                okText="Сохранить"
                okButtonProps={{shape: "round"}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                <Form
                    form={editMentorForm}
                    layout="vertical"
                >
                    <Form.Item
                        label="Фамилия:"
                        labelAlign="left"
                        name="lastName"
                        initialValue={selectedMentor?.lastName}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                            { required: true, message: 'Фамилия: обязательное поле!' },
                        ]}
                    >
                        <Input placeholder="Введите фамилию.." style={{borderRadius: "32px"}} maxLength={40} />
                    </Form.Item>
                    <Form.Item
                        label="Имя:"
                        labelAlign="left"
                        name="firstName"
                        initialValue={selectedMentor?.firstName}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[{ required: true, message: 'Имя: обязательное поле!' }]}
                    >
                        <Input placeholder="Введите имя.." style={{borderRadius: "32px"}} maxLength={40} />
                    </Form.Item>
                    <Form.Item
                        label="Отчество:"
                        labelAlign="left"
                        name="patronymic"
                        initialValue={selectedMentor?.patronymic}
                    >
                        <Input placeholder="Введите отчество (если оно есть).." style={{borderRadius: "32px"}} maxLength={40} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={isDeleteMentorModal}
                centered
                onCancel={() => {
                    setIsDeleteMentorModal(false);
                }}
                onClose={() => {
                    setIsDeleteMentorModal(false);
                }}
                title="Деактивация / удаление ментора"
                footer={[
                    <Button key="back" shape="round" type="text" onClick={() => {
                        setIsDeleteMentorModal(false);
                    }}>
                        Отмена
                    </Button>,
                    <Button key="submit" shape="round" type="primary" onClick={() => {
                        if (selectedMentor) {
                            httpClient.axios().delete(config.endPoints.deleteUser
                                .replace('{userId}', selectedMentor.id.toString())).then(() => {

                                setIsDeleteMentorModal(false);
                                notification.success({
                                    message: 'Пользователь успешно удален из системы!'
                                });
                                updateMentorsData();
                            });
                        }
                    }}>
                        Удалить
                    </Button>,
                    <Button key="submit" shape="round" type="primary" onClick={() => {
                       if (selectedMentor) {
                           httpClient.axios().post(config.endPoints.deactivateUser
                               .replace('{userId}', selectedMentor.id.toString())).then(() => {

                               setIsDeleteMentorModal(false);
                               notification.success({
                                   message: 'Ментор успешно деактивирован!'
                               });
                               updateMentorsData();
                           });
                       }
                    }}>
                        Деактивировать
                    </Button>,
                ]}
                width={600}
                okButtonProps={{disabled: true}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                <Text>Вы действительно хотите удалить данного ментора?</Text>
            </Modal>
        </div>
    )
};
